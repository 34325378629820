<template>
    <div class="recover-pass">
      <h2>Restart your Password</h2>
      <form @submit.prevent="sendRecoveryRequest">
        <div class="form-group">

        <label for="contact">Email:</label>
        <input
          v-model="contact"
          type="text"
          id="contact"
          class="form-control" 
          placeholder="Insert email"
          required
        />
      </div>
      <div class="action-group"> 
        <button           class="form-control" 
         type="submit">Restart Password</button>
      </div>
      </form>
      <p v-if="message">{{ message }}</p>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        contact: '',
        message: ''
      };
    },
    methods: {
      async sendRecoveryRequest() {
        try {
          const response = await axios.post('/api/password-recovery', { contact: this.contact });
          this.message = response.data.message || 'Please, check your email.';
        } catch (error) {
          this.message = 'We had an error sending the password recovery request.';
        }
      }
    }
  };
  </script>
<style>
.recover-pass {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}
.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative; /* Enable positioning for tooltip */
}

.tooltip {
  position: absolute;
  top: 100%; /* Position below the form group */
  left: 0;
  background-color: #95f1ff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15); /* Add a shadow */
  font-size: 14px;
  color: #333;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Add transform transition for animation */
}

.form-group:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px); /* Move the tooltip slightly upwards */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

</style>