<template>
home

</template>
  
  <script lang="ts">
  export default {
    name: 'Home',
    components: {},
    props: {},
    data() {
      return {};
    },
    methods: {}
  };
  </script>
  
  <style scoped>
  /****/
.description {
  text-align: center; /* Horizontally center the content */
  display: flex; /* Use flexbox for vertical centering */
  flex-direction: column;
  justify-content: center; /* Vertically center the content */
  align-items: center; /* Align items to center */
  height: 100vh; /* Set the height to full viewport height for better vertical centering */
  margin-top: -100px;
}

h2, p {
  text-align: center; /* Ensure heading and paragraph are also centered */
}

.buttons {
  display: flex;
  gap: 10px;
}

.login-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.login-button:hover {
  background-color: #333;
}

  </style>