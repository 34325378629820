export function integerToUUID(name: string, int: number): string {
  // Combine the name and integer
  const combinedString = `${name}-${int}`;

  // Hash the combined string to produce a consistent output
  let hash = 0;
  for (let i = 0; i < combinedString.length; i++) {
    const char = combinedString.charCodeAt(i);
    hash = (hash << 5) - hash + char;
    hash = hash & hash; // Convert to 32-bit integer
  }

  // Convert the hash to a hexadecimal string and pad it to ensure length
  const hex = Math.abs(hash).toString(16).padStart(8, '0');

  // Format the result to look like a UUID
  const uuid = `${hex.substring(0, 8)}-${hex.substring(0, 4)}-${hex.substring(4, 8)}-${hex.substring(0, 4)}-${hex.substring(4, 12)}`;

  return uuid;
  }