<template>
     <nav class="navbar">
    <div class="navbar-logo">
        <img src="../assets/logoMetabolica.svg" alt="Metabolica Logo">
    </div> 
    <div class="navbar-menu" v-if="isAuthenticated">
      <ul>
        <!--library-->
        <li>
          <router-link to="/create-from-library" active-class="active move" @mouseover="toggleMenu1" >
          <font-awesome-icon icon="book" /> 
          Library
          <font-awesome-icon icon="angle-up" class="angle-down" />
        </router-link>
        </li>
        <!--create task-->
        <li>
          <router-link to="/organism-task" active-class="active" >
            <font-awesome-icon icon="sheet-plastic" /> 
            Organism Task Page
            <font-awesome-icon icon="angle-up" class="angle-down" @mouseover="toggleMenu2"/>
          </router-link>
        </li>
        <!--profile-->
        <li>
          <router-link to="/dashboard" id="profile" active-class="active move" >
          <font-awesome-icon icon="user" /> 
          Your profile
          <font-awesome-icon icon="angle-down" />
        </router-link>
        </li>

      </ul>
      <!--LIBRARY with submenu ORGANISMS-->    
    </div>
 
    </nav>
  </template>
  
  <script setup lang="ts">
import { computed, ref,watch, onMounted } from 'vue';
import '../assets/main.css' // Adjust the path to where you saved App.css or main.css
import { useAuthStore } from '@stores/authStore';




const authStore=useAuthStore();
authStore.checkAuthCacheExpiration()

const baseUrl = import.meta.env.VITE_APP_BASE_URL;

const isAuthenticated = computed(() => authStore.isAuthenticated)

watch(isAuthenticated, (newValue, oldValue) => {
  console.log(`isAuthenticated changed from ${oldValue} to ${newValue}`)
  // Perform any additional actions based on authentication changes
  if (!newValue) {
    // Example action when user logs out, e.g., redirect or clear data
    console.log("User is not authenticated")
  }
})

onMounted(() => {
  const loginTimestamp = localStorage.getItem('loginTimestamp')
  if (loginTimestamp) {
    const loginDate = new Date(parseInt(loginTimestamp, 10)) // Convert to Date object
    console.log("User login timestamp:", loginDate)
  } else {
    console.log("No login timestamp found.")
  }
})
  </script>
  
  <style scoped>

  </style>