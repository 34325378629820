<template>
<div class="container">
      <div id="library-selected">
        <div id="header-library">
          <h2>Library</h2>
        </div>

          <div class="collapsible-container">
          <OrganismModal 
            :is-open="showModal" 
            title="Create new organism with condition"
            :items="organism_data"
            :parent_list="species"
            :parent_name="parents.metabolic_network.parent_name"            
            @close="showModal=false" 
            @created="createdOrg"
            @send_parent="receiveParent"
          >
          </OrganismModal>          
          </div>
      <div id="library">
      <div class="grid landscape">
        <Archive 
        class="archive column" 
        @select="handleSelect"  
        @to_next_filter="handleNextFilterTrx"
        @send_elements="handleMetabolicNetworks"
        @add_new_parent="handleNewParentMN"
        name="metabolic network" 
        endpoint="/api/gems/gems" 
        :parent_list="species"
        :parent="specie"
        :parent_name="parents.metabolic_network.parent_name"
        :filter_field="parents.metabolic_network.filter_field"  
        :filters_list="specie_names"      
        ref="selectedGems" />
        <Archive 
        class="archive column"  
        @select="handleSelect"  
        name="transcriptomes" 
        endpoint="/api/transcriptomes/transcriptomes" 
        :parent="metabolic_network"
        :parent_list="elementsMN"
        :parent_name="parents.transcriptomes.parent_name"
        :filter_field="parents.transcriptomes.filter_field"
        :filters_list="networks_filter"
        ref="selectedTranscriptomes" />
        <Archive 
        class="archive column" 
        @select="handleSelect"  
        name="mediums" 
        endpoint="/api/mediums/mediums"
        :parent="metabolic_network"
        :parent_list="elementsMN"
        :filters_list="networks_filter"
        :parent_name="parents.mediums.parent_name"
        :filter_field="parents.mediums.filter_field"
        ref="selectedMedium" />

      </div>
     </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { unref, ref, onMounted, reactive, computed, watch, toRaw } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';
import { defineComponent } from 'vue';
import Archive from './Archive.vue';
import OrganismModal from './OrganismModal.vue';

const router = useRouter();
const selectedTranscriptomes = ref(null);
const selectedMedium = ref(null);
const selectedGems = ref(null);
const networks_filter = ref([]);
const loading = ref(false);
const specie= ref({})
const specie_names = ref([]) 
const metabolic_network = ref({})

async function receiveParent(parent:Object) {
  console.log("Receive parent", parent)
  specie.value = parent
  specie_names.value = [parent.name]
  console.log("speccie name", specie_names.value)
}


async function handleNextFilterTrx(items:Array<string>): Promise<void>{
  networks_filter.value = items;
} 

async function handleNewParentMN(item:Object){
  const id = item.id;
  elementsMN.value[id] = item
  networks_filter.value = [item.name.toLocaleLowerCase()]
}

// reactive
const organism = ref({});
const parents = {
  "metabolic_network":{parent_name:'specie', filter_field:'name'},
  "transcriptomes":{parent_name:'metabolic_network', filter_field:'name'},
  "mediums":{parent_name:'metabolic_network', filter_field:'name'},  
}
// Reactive dictionaries for each archive
const transcriptomes = reactive<{ [key: string]: string | number }>({});
const mediums = reactive<{ [key: string]: string | number }>({});
const gems = reactive<{ [key: string]: string | number }>({});
const organism_data = reactive<{ [key: string]: string | number }>({});
const species = reactive<{ [key: string]: string | number }>({});
const elementsMN = ref({});
const showModal = ref(true);


async function handleMetabolicNetworks(elements:Object){
  elementsMN.value = elements
}

const confirmAction = () => {
  showModal.value = false;
};


function createdOrg(new_org:Object){
  organism.value = new_org
}

function handleSelect(
  archiveName: string,
  selected: Object
) {
  let parent = '';
  console.log("Receive item", archiveName, selected)

  // Store the selected dictionary based on archive name
  switch (archiveName) {
    case 'transcriptomes':
      transcriptomes.value = selected;
      parent = 'metabolic_network'
      break;
    case 'mediums':
      mediums.value = selected;
      parent = 'metabolic_network'
      break;
    case 'metabolic network':
      gems.value = selected;
      parent = 'specie'
      metabolic_network.value = selected
      break;
  }
  organism_data[archiveName] =  {"id":selected.id, "name":selected.name, "parent": selected[parent].name}
}


const archives = ref([
  { name: 'transcriptomes', endpoint: '/api/transcriptomes/transcriptomes' },
  { name: 'mediums', endpoint: '/api/mediums/mediums' },
  { name: 'metabolic network', endpoint: '/api/gems/gems' },
]);

function goToCreatePage() {
  router.push({ name: 'CreateFromLibrary' });
}

function goToOrganismModal(){
  router.push({ name: 'OrganismModal' });
}

/* fetch all species */
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function fetchSpecies() {
  loading.value = true;
  try {
    const response = await api.get('/api/specie/species');
    species.value = response.data;
    
  } catch (error) {
    console.error('Error fetching organisms:', error);
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}


onMounted(() => {
  fetchSpecies();
});
</script>
<style scoped>
.grid {
  display: flex;
  gap: 20px; /* Space between columns */
}

.column {
  flex: 1; /* Each column takes equal width */
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Stretches each component to the same height */
}

.column > * {
  flex: 1; /* Ensures each component takes the full height of the tallest one */
}

.container {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente línea si no hay suficiente espacio */
  justify-content: center; /* Centra los elementos horizontalmente */
}

#header-library {
  display: grid;
  grid-template-columns: auto 1fr; /* Define grid columns */
  grid-gap: 10px; /* Optional spacing between elements */
  align-items: center; /* Vertically center child elements (optional) */
}

.landscape {
  display: flex;
  flex-wrap: wrap; /* Permite que los elementos se envuelvan a la siguiente línea si no hay suficiente espacio */
  justify-content:center;
}

.archive {
  flex: 1; /* Hace que cada elemento ocupe el mismo espacio disponible */
  min-width: 200px; /* Establece un ancho mínimo para cada elemento */
  padding: 10px;
  border: 1px solid #cccccc;
  margin: 5px;
}

.book-icon {
  color: #333f;
}

.item {
  /* Estilos para los elementos dentro de cada cuadrícula */
}

.content {

}

h2,h3,h4, strong {
  color: #333;
}

strong {
  background-color: #d3d3d3;
  /*width: 50%;*/
  padding: 4px;
  border-radius: 4px;
  text-align: center;
  margin-left: 4px;
}

ul{
  list-style: none;
}

li {
  margin-bottom: 10px;
}

.content {
  background-color: #ffffff;
  border-radius: 4px;
  margin-left:30px;
  padding: 10px;
}

.create-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #333;
}
</style>