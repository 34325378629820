<template>
  <div class="organism-list-page">
    <div class="search-organisms">
      <div class="search-bar">
    <label>
      <font-awesome-icon icon="fa-magnifying-glass" /> 
    </label>
    <input
      class="search-input"
      type="text"
      placeholder="Search Organisms here..."
      v-model="searchTerm"
    />
  </div>
      <div class="filter-valid">
        <font-awesome-icon icon="filter" />
        <label for="filter-valid"> Filter by status:</label>
        <select 
          id="filterValid" 
          v-model="filterValid" 
          class="sort-select"
        >
        <option value="" data-placeholder="Seleccionar organismos"></option>
          <option value="all">All Organisms</option>
          <option value="valid">Valid Organisms</option>
          <option value="invalid">Invalid Organisms</option>
        </select>
        <font-awesome-icon icon="question"
          v-tooltip.bottom="{ text: 'Select organizations based on their status: Valid Organisms/Invalid Organisms' }"
        />
      </div>
      <button @click="clearSearch" class="create-button clear-all"
      title="Clear search terms and filter selection"
      >
        <font-awesome-icon icon="fa-eraser" />
        Clear all
      </button>
      <div>
      
      </div>
    </div>
    <div class="organism-list">
    <h2>
      <font-awesome-icon icon="fa-dna" />
      Organism List ({{ filteredOrganisms.length }} found)
    </h2>
    <div class="btn">
      <button 
        @click="goToCreatePage" 
        class="create-button"
        title="Add a new organism with its RNA-seq & culture medium data"
        aria-haspopup="true"
        >
        <font-awesome-icon 
        icon="folder-plus"
        />
        New Organism
      </button> |
      <button 
      class="create-button"
      @click="goToCreateFromLibrary" 
      >
        <font-awesome-icon icon="list" />
        Create from library
      </button>
      <font-awesome-icon icon="question" />
    </div>
    <table class="organism-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Organism Name</th>
          <th>Condition</th>
          <th>Date Created</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="7">Loading...</td>
        </tr>
        <tr v-else-if="!filteredOrganisms.length">
          <td colspan="7">No organisms found.</td>
        </tr>
        <tr v-else v-for="organism in paginatedOrganisms" :key="organism.id" class="organism-row">
          <td>{{integerToUUID(organism.organism,organism.id) }}</td>
          <td>{{ organism.organism }}</td>
          <td>{{ organism.condition }}</td>
          <td>{{ organism.date_creation }}</td>
          <td class="organism-valid">
      <font-awesome-icon
        :icon="organism.valid ? 'fa-check-circle' : 'fa-times-circle'"
        :class="{ 'success-icon': organism.valid, 'no-valid': !organism.valid }"
        :title="organism.valid ? 'Valid Organism' : 'Invalid organism'"
      />
    </td>
          <td>
            <button :title="`Edit organism`" @click="editOrganism(organism.id)" class="style-actions">
              <font-awesome-icon icon="fa-edit" class="text-blue" />
            </button> |
            <button :title="`Delete organism`" @click="deleteOrganism(organism.id)" class="style-actions">
              <font-awesome-icon icon="fa-trash" class="text-danger" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
      <span class="pagination-current">{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
    </div>
  </div>
</div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';
import {integerToUUID} from '@tools/uuid'

const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
baseURL: configStore.baseUrl,
headers: {
  'Content-Type': 'application/json',
},
});

console.log("URL",configStore.baseUrl)


interface User {
  username: string;
}

interface Organism {
  id: number;
  organism: string;
  condition: string;
  date_creation: string;
  valid: boolean;
  user: User;
}

const organisms = ref<Organism[]>([]);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = 5; // Set page size
const totalPages = computed(() => Math.ceil(organisms.value.length / pageSize));

const paginatedOrganisms = computed(() => {
  const startIndex = (currentPage.value - 1) * pageSize;
  return filteredOrganisms.value.slice(startIndex, startIndex + pageSize);
});

const router = useRouter();

// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function fetchOrganisms() {
  console.log("INICIANDO FETCH DE ORGANISMS")
  loading.value = true;
  try {
    console.log("LLAMADA A ENDPOINT ORGANISMS/LIST", api)
    const response = await api.get('/api/organisms/organisms');
    organisms.value = response.data;
    console.log("RESULTADOS",(response))

  } catch (error) {
    console.error('Error fetching organisms:', error);
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

function editOrganism(id: number) {
  console.log(`Edit organism with ID: ${id}`);
}

//------ DELETE
async function deleteOrganism(id: number) {
  const organismToDelete = organisms.value.find(organism => organism.id === id);
  if (organismToDelete) {
    const confirmed = confirm(`¿Está seguro que desea eliminar el organismo "${organismToDelete.organism}"?`);
    if (confirmed) {
      try {
        await api.delete(`/api/organisms/organisms/${id}`);
        console.log(`Organismo "${organismToDelete.organism}" eliminado correctamente.`);
        organisms.value = organisms.value.filter((organism) => organism.id !== id);
      } catch (error) {
        console.error('Error eliminando organismo:', error);
        alert('Hubo un error al eliminar el organismo.');
      }
    }
  }
}
//------ 

function goToCreatePage() {
  router.push({ name: 'CreateOrganism' });
}

function goToCreateFromLibrary() {
  router.push({ name: 'CreateFromLibrary' });
}

// Function to clear search term and filter
function clearSearch() {
  searchTerm.value = '';
  filterValid.value = null;
  currentPage.value = 1; // Reset pagination
}

//......... BÚSQUEDA DE ORGANISMOS
const searchTerm = ref('');
const filterValid = ref(null); // Filtrar por válido/no válido
// Create a computed property to filter organisms based on searchTerm
const filteredOrganisms = computed(() => {
  // Filtrado basado en el término de búsqueda (incluyendo ID)
  const searchLower = searchTerm.value.toLowerCase();
  let filtered = organisms.value.filter(organism => {
    const organismLower = (organism.id + ' ' + organism.organism + ' ' + organism.condition).toLowerCase();
    return organismLower.includes(searchLower);
  });

  // Filtrar por validez
  filtered = filtered.filter(organism => {
    // Si filterValid es null o "all", mostrar todos los organismos
    return filterValid.value === null || filterValid.value === 'all' || organism.valid === (filterValid.value === 'valid');
  });

  // Ordenamiento por fecha de creación (más reciente primero)
  return filtered.sort((a, b) => {
    // Convertir fechas a objetos Date para una comparación precisa
    const dateA = new Date(a.date_creation);
    const dateB = new Date(b.date_creation);

    // Ordenar de manera descendente (más reciente primero)
    return dateB.getTime() - dateA.getTime();

    
  });
});

// Update organism search results when searchTerm changes
watch(searchTerm, () => {
  currentPage.value = 1; // Reset pagination on search
});

// Search organisms based on searchTerm
async function searchOrganisms() {
  if (!searchTerm.value.trim()) {
    // If search term is empty, fetch all organisms again
    await fetchOrganisms();
  }
}
//.........

// Fetch organisms when the component is mounted
onMounted(() => {
  fetchOrganisms();
});
</script>

<style scoped>
.tooltip {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.tooltip-trigger:hover .tooltip {
  visibility: visible;
}

.search-organisms {
  margin-bottom: 20px;
  border:1px solid #ccc;
  border-radius: 4px;
  padding: 10px;

}

.search-organisms label {
  margin-right: 10px;
}

.organism-list {
  border:1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.filter-valid{
  margin-top: 10px;
  margin-bottom: 10px;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  display: flex;
  /*width: 100%;  Ocupa todo el ancho disponible */
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  box-sizing: border-box;
  padding: 7px;
  width: 50%;
}

.search-input:focus {
border-color: #646464;
outline: none;
box-shadow: 0 0 0 3px #d2d2d2;
}

.organism-list-page {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


h2 {
  color: #333;
}

.sort-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.sort-select option[value=""]::before {
  content: attr(data-placeholder);
}
.sort-select option[value=""] {
  display: none;
}

.create-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #333;
}

.organism-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.success-icon {
  color: green; /* Adjust color as needed */
  cursor: pointer;
}

.no-valid {
  color: red;
  cursor: pointer;
}

.organism-table th,
.organism-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  font-size: 16px;
}


.organism-table th {
  background-color: #f2f2f2;
  color: #333;
}


.organism-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.organism-table tr:hover {
  background-color: #f1f1f1;
}

.action-link {
  color: #3498db;
  text-decoration: none;
  cursor: pointer;
}

.action-link:hover {
  text-decoration: underline;
}

.style-actions {
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 10px 20px;
  cursor: pointer;
}

.text-blue {
  color: #555555;
  cursor: pointer;
  transition: transform 0.1s ease; /* Add transition effect */
}

.text-blue:hover {
  color: #000000;
  transform: scale(1.1); /* Zoom in by 10% */
}

.text-danger {
  color: #555555;
  cursor: pointer;
  transition: transform 0.1s ease; /* Add transition effect */
}

.text-danger:hover {
  color: #000000;
  transform: scale(1.1); /* Zoom in by 10% */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pagination-button {
  background-color: #000000;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}
</style>

