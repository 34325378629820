<template>
    <div class="description">
    
      <h2>Harnessing<br>Celullar Metabolism<br>To create advanced biotechnologies</h2>
      
      <div class="buttons">
        <button @click="toLogin" class="login-button">Log in</button>
        <button @click="toSignIn" class="login-button">Sign in</button>
      </div>
  
      <p>
        Focus on your research, not the technical detail. Our online platform<br>
        instant estimates the cellular metabolism via <a href="www.pheflux.com/">Pheflux</a> with an easy-to-<br>use interface. 
        No servs, no programming, no hassle.
      </p>
    </div>
  </template>
    
  <script setup lang="ts">
  import { useRoute, useRouter } from 'vue-router';
  import {onMounted, ref, Ref} from 'vue'
    
  const route = useRoute()
  const router = useRouter()

  onMounted(async () => {
    console.log('Before Router Preparation', route.name);
    await router.isReady();
    console.log('After Router Preparation', route.name);
})

  function toLogin() {
    //debugger;
    router.push('/login'); // Redirige a la página de dashboard
  }

  function toSignIn() {
    //debugger;
    router.push('/register'); // Redirige a la página de dashboard
  }


    
    </script>
    
    <style scoped>

  .description {
    text-align: center; /* Horizontally center the content */
    display: flex; /* Use flexbox for vertical centering */
    flex-direction: column;
    justify-content: center; /* Vertically center the content */
    align-items: center; /* Align items to center */
    height: 100vh; /* Set the height to full viewport height for better vertical centering */
    margin-top: -100px;
  }
  
  h2, p {
    text-align: center; /* Ensure heading and paragraph are also centered */
  }
  
  .buttons {
    display: flex;
    gap: 10px;
  }
  
  .login-button {
    padding: 10px 20px;
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #333;
  }
    </style>