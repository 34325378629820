<template>
  <div>
    <h2>Restablecer Contraseña</h2>
    <form @submit.prevent="resetPassword">
      <label for="newPassword">Nueva Contraseña:</label>
      <input
        v-model="newPassword"
        type="password"
        id="newPassword"
        required
      />
      
      <label for="confirmPassword">Confirmar Contraseña:</label>
      <input
        v-model="confirmPassword"
        type="password"
        id="confirmPassword"
        required
      />
      
      <button type="submit">Cambiar Contraseña</button>
    </form>
    <p v-if="message">{{ message }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      message: ''
    };
  },
  methods: {
    async resetPassword() {
      if (this.newPassword !== this.confirmPassword) {
        this.message = 'Las contraseñas no coinciden.';
        return;
      }
      
      try {
        const response = await axios.post('/api/password-reset', {
          newPassword: this.newPassword,
          confirmPassword: this.confirmPassword,
          token: this.$route.query.token // Asumiendo que el token está en la URL
        });
        this.message = response.data.message || 'Contraseña actualizada correctamente.';
      } catch (error) {
        this.message = 'Hubo un error al actualizar la contraseña. Intenta de nuevo.';
      }
    }
  }
};
</script>
