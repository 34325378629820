<template>
  <div class="organism-task-list-page">
    <h2>Organism Task List</h2>
    <button 
    @click="goToCreateTaskPage" 
    class="create-button"
    title="Create a new task"
    >
    <font-awesome-icon icon="folder-plus"
    /> New Task</button>
    <div class="sorting">
      <label for="sortOrder">Sort by Created At:</label>
      <select id="sortOrder" v-model="sortOrder" class="sort-select">
        <option value="asc">Ascending</option>
        <option value="desc">Descending</option>
      </select>
    </div>
    
    <table class="task-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Task Name</th>
          <th>Status</th>
          <th>Created At</th>
         <!-- <th>Created By</th> -->
          <th>Result Link</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="6">Loading...</td>
        </tr>
        <tr v-if="!loading && tasks.length === 0">
          <td colspan="6">No tasks found.</td>
        </tr>
        <tr v-for="task in sortedTasks" :key="task.id">
          <td>{{ task.id }}</td>
          <td>{{ task.name }}</td>
          <td>{{ task.status }}</td>
          <td>{{ task.created_at }}</td>
         <!--<td>{{ task.created_by.username }}</td>-->
          <td>
            <a v-if="task.result_link" :href="get_url(task)" target="_blank">View Results</a>
            <span v-else>N/A</span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
      <span class="pagination-current">{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

interface User {
  username: string;
}

interface Task {
  id: number;
  name: string;
  status: string;
  created_at: string;
  //created_by: User;
  result_link: string | null;
  result_file: string | null;
}

const tasks = ref<Task[]>([]);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = 5; // Set page size
const sortOrder = ref<'asc' | 'desc'>('asc'); // Sorting order
const totalPages = computed(() => Math.ceil(tasks.value.length / pageSize));


///api/organism_processing/tasks
const sortedTasks = computed(() => {
  const sorted = [...tasks.value].sort((a, b) => {
    if (sortOrder.value === 'asc') {
      return new Date(a.created_at).getTime() - new Date(b.created_at).getTime();
    } else {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();

    }
  });

  const start = (currentPage.value - 1) * pageSize;
  return sorted.slice(start, start + pageSize);
});

const router = useRouter();

function get_url(task:Task) {
  const url = configStore.baseUrl;
  return `${url}/media${task.result_file}`
}

async function fetchTasks() {
  console.log("ORganism task list page");
  loading.value = true;
  try {
    console.log("fetch to /api/organism_processing/tasks");
    console.log(api);
    //const response = await api.get('/api/organism_processing/tasks');
    const response = await api.get('/api/organism_processing/tasks')  
    .then(response => {
    console.log('Response:', response);
    return response
  })
  .catch(error => {
    console.error('Error:', error);
    return error
  });

    tasks.value = response.data;
    console.log("result", response)

  } catch (error) {
    console.error('Error fetching tasks:', error);
    alert('There was an error fetching the tasks.');
  } finally {
    loading.value = false;
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

function goToCreateTaskPage() {
  console.log()
  router.push({ name: 'OrganismTask' }); // Navigate to the create task page
}

// Fetch tasks when the component is mounted
onMounted(() => {
  fetchTasks();
});
</script>

<style scoped>
.organism-task-list-page {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  color: #333;
}

.create-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #333;
}

.sorting {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.sort-select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.task-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.task-table th,
.task-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  font-size: 16px;
}

.task-table th {
  background-color: #f2f2f2;
  color: #333;
}

.task-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.task-table tr:hover {
  background-color: #f1f1f1;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pagination-button {
  background-color: #353535;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}

a {
  color: #3498db;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}


</style>
