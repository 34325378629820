<template>
    <div class="dropdown-container">
      <div class="parent-selector">
      <input
        type="text"
        class="form-control"
        v-model="inputValue"
        placeholder="Write to filter"
        @input="emitInputValue"
        @focus="showDropdown"
        @blur="hideDropdown"
        @keydown="handleKeyDown"
      />

    </div>
      <div v-if="isDropdownVisible && filteredOptions.length>0" class="dropdown">
        <ul>
          <li
            v-for="(option, index) in filteredOptions"
            :key="option.name"
            @click="selectOption(option)"
            :class="{ highlighted: index === highlightedIndex }"
          >
            {{option.specie?option.specie.name:''}} {{ option.name }}
          </li>
        </ul>
      </div>
    </div>
</template>
  
  
<script lang="ts" setup>
import { ref, computed, onMounted, watch } from 'vue';
import { defineProps, defineEmits, PropType } from 'vue';


const props = defineProps({
    parent_name : {
      type: String,
      default:''
    },
    parent_list: {
      type:Object,
      default:{}
    },

});

const emit = defineEmits<{

(e: 'send_parent', parent_id:Object):void;
(e: 'update:modelValue', input_text:string):void;
}>();

function emitInputValue(){
  emit('update:modelValue', inputValue.value);
}


const parent_is_specie = computed (()=>{
  return props.parent_name==='specie'
})

const exists_parent = computed(()=>{
  /* takes  inputValue.value is in list */
  if (props.parent_list.length>0){
  return props.parent_list.some(inputValue.value.toLocaleLowerCase())
  }else{return false}
})

const inputValue = ref<string>('');
const isDropdownVisible = ref<boolean>(false);
const highlightedIndex = ref<number>(-1);
const selectedParent = ref({});


const filteredOptions = computed(() =>{

  if (props.parent_list.length>0){
  const result = props.parent_list.filter(option => {
                if (option){
                  if (option.name){
                    return option.name.toLowerCase().includes(inputValue.value.toLowerCase())
                  }else{
                    return false
                  }
                }else {
                  return false
                }
              }
              )
  return result
}else if (props.parent_list.value.length>0){
  const result = props.parent_list.value.filter(option => {
                if (option){
                  if (option.name){
                    return option.name.toLowerCase().includes(inputValue.value.toLowerCase())
                  }else{
                    return false
                  }
                }else {
                  return false
                }
              }
              )
  return result

}
else {
  return []
}}
);


const selectOption = (option: Object) => {
  
  /* option is specie object */
  selectedParent.value = option;
  inputValue.value =  option.name;
  isDropdownVisible.value = false;
  highlightedIndex.value = -1;

  emit('send_parent', selectedParent.value)
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (!isDropdownVisible.value) return;

  if (event.key === 'ArrowDown') {
    highlightedIndex.value = (highlightedIndex.value + 1) % filteredOptions.value.length;
    event.preventDefault();
  } else if (event.key === 'ArrowUp') {
    highlightedIndex.value =
      (highlightedIndex.value - 1 + filteredOptions.value.length) % filteredOptions.value.length;
    event.preventDefault();
  } else if (event.key === 'Enter') {
    if (highlightedIndex.value >= 0) {
      selectOption(filteredOptions.value[highlightedIndex.value]);
    }
    event.preventDefault();
  }
};

// Mostrar el dropdown cuando el usuario escribe en el input
const showDropdown = () => {
  isDropdownVisible.value = true;
};

// Ocultar el dropdown cuando el input pierde el foco
const hideDropdown = () => {
  setTimeout(() => {
    isDropdownVisible.value = false;
    highlightedIndex.value = -1;
  }, 500); // Un pequeño retraso para permitir seleccionar
};
</script>

<style scoped>
.search-input {
  display: flex;
  /*width: 100%;  Ocupa todo el ancho disponible */
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
}

.search-input:focus {
border-color: #646464;
outline: none;
box-shadow: 0 0 0 3px #d2d2d2;
}

.dropdown-container {
  position: relative;
}

.dropdown {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 150px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 8px;
  cursor: pointer;
}

.dropdown li.highlighted {
  background-color: #e0e0e0; /* Para resaltar la opción seleccionada */
}

.dropdown li:hover {
  background-color: #f0f0f0;
}
</style>
