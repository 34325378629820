<template>
  <div class="content">
    
    <div class="library-content">

      <div class="archive-block">
        <div> {{parent.id}} --- {{ parent.name }}</div>

        <div class="created-msg">
          <div class="ok" v-if="error===false">New {{ props.name }} was created</div>
          <div class="fail" v-if="error===true">{{ message }}</div>
        </div>        
             
      <h3 class="highlighted">
         {{ capitalize(name) }}
      </h3>
          <input
          class="search-input"
          type="text"
          placeholder="Search libraries here..."
          v-model="searchTerm"
        />

        <div class="btn">
          <button 
            @click="createArchive"
            class="create-button"
            title="Click to add a new file"
            >
            <font-awesome-icon icon="folder-plus" />
            <font-awesome-icon icon="arrow-right" />
            <font-awesome-icon icon="file" />
            <!-- Add new {{ capitalize(name) }} -->
          </button>

          <button 
            @click="clearSelection"
            class="clear-button"
            title="Click here to clear everything"
            >
            <font-awesome-icon icon="broom" />
            <!--Clear -->
          </button>
        </div> 

        <div class="elements"></div>
        <h4 class="selected-count">
          Selection: 
          <div v-if="selected">{{ selected.name }}</div>
        </h4>
      </div>
      <div class="archive-block">
      <ul class="list-select-item">
          <li 
              class="list-item"
              v-for="(item, id) in filteredElements" :key="id"
              >
              <div class="radio-button">
              <input 
                type="radio" 
                id="huey" 
                :name="'archive-'+props.name" 
                :value="item.id" 
                checked 
                @change="selectItem(item)" 
              />
            </div>
              <div v-if=item[props.parent_name] class="specie-selection">
                <span v-if="props.parent_name==='specie'">
                  <font-awesome-icon icon="fa-dna" />
                  {{item[props.parent_name][props.filter_field]}}
                </span> 
                <p><font-awesome-icon icon="check" /> {{ item.name }} </p> 
                <hr>
              </div>
              <a 
                class="download-link"
                v-if="item.filepath" 
                :href="get_url(item)" 
                target="_blank" 
                title="Download archive"
              >
              <font-awesome-icon icon="download" class="download-icon"/>
            </a>
          </li> 
      </ul>
      <!--Pagintation here!-->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
        <span> {{ currentPage }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>  
      </div>
      
    </div>

      <div class="archive-modal archive-block">

        <CreateArchiveModal
                title="New Archive"
                :name="props.name"
                :endpoint="props.endpoint"
                :is-open="showModal"  
                :parent_list="props.parent_list"  
                :parent_name="parent_name"      
                :parent="parent"                   
                @error_signal="handleError"          
                @close="showModal=false" 
                @created="createdArchive">
        </CreateArchiveModal>

      </div>
    </div>
  <div>
</div>
<div class="footer">

</div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch, defineEmits,reactive, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';
import CreateArchiveModal from './CreateArchiveModal.vue';
import {capitalize} from '@tools/functions'

const configStore = useConfigStore();
const authStore = useAuthStore();
const error = ref('');
const message = ref('');
const showModal = ref(false);



const handleError = (msg) =>{
  error.value = msg.error;
  message.value = msg.message;
}

const createArchive = () =>{
  console.log("Open modal...create new archive")
  showModal.value = true
}

const confirmAction = () => {
  showModal.value = false;
};


const props = defineProps({
    name: String,
    endpoint: {
      type: String,
      required: true // Make endpoint prop mandatory
    },
    parent_name: {
    type: String,
    required: true
  },
  filter_field: {
    type: String,
    required: true
  },
  filters_list:{
    type:Array<string>,
    default:[]
  },
  parent_list:{
    type:Object,
    default:{}
  },
  parent: {
        default:null
    }    
})

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function set_selected_archive(key:string){
  const radioButtons = document.querySelectorAll(`input[name="archive-${props.name}"]`);
  radioButtons.forEach(radioButton => {
    const selectedValue = radioButton.value;
    if (key===selectedValue){
      radioButton.checked = true;
    } else {
      radioButton.checked = false;
    }
  });
}

function clear_selected_archive(){
  const radioButtons = document.querySelectorAll(`input[name="archive-${props.name}"]`);
  radioButtons.forEach(radioButton => {
    radioButton.checked = false;
  });
}

function clearSelection(){
  clear_selected_archive()
  emit('to_next_filter', [])
  selected.value = {}
  searchTerm.value = ''

}

const updateSelectorsAfterRender = async () => {
  await nextTick(); // Wait for DOM update
  if (control.value){
    clear_selected_archive(); // Set all selectors to false
  }
  control.value = false;
};

const emit = defineEmits<{
  (e: 'select', key: string, object: Object): void;
  (e: 'send_elements', object:Object):void;
  (e: 'to_next_filter', object:Array<string>):void;
  (e: 'add_new_parent', object:Array<string>):void;
}>();

function createdArchive(new_org:Object){
  const created_key = new_org.id.toString();
  filteredElements.value[created_key] = new_org;
  selected.value = new_org;
  set_selected_archive(created_key);
  emit('send_elements', filteredElements);
  emit('add_new_parent', [new_org.name]);
  emit('select', props.name, new_org);
}

function get_url(item:Element) {
  const url = configStore.baseUrl;
  const link = `${url}/media${item.filepath}`
  //debugger;
  return link;
}
const selected = ref({});



function selectItem(item:Object){
    selected.value = item;
    //debugger;
    console.log("Selected item->", item)
    emit('select', props.name, item);
    emit('to_next_filter', [selected.value.name.toLowerCase()])
}


const router = useRouter();
const elements = ref<Element[]>([]);
const loading = ref(false);
const api = axios.create({
baseURL: configStore.baseUrl,
headers: {
  'Content-Type': 'application/json',
},
});


//***

//** *

const control = ref(true)
//......... BÚSQUEDA DE ORGANISMOS
const searchTerm = ref('');
const filterValid = ref(null); // Filtrar por válido/no válido

const filteredElements = ref({});
const debounceTimeout = ref(null);


watch(
  () => props.parent,
  (newValue, oldValue) => {
    console.log('Parent changed from', oldValue, 'to', newValue);
    console.log("SET SEARCH TERM", newValue.name)
    if (props.parent_name==='specie'){
    searchTerm.value = newValue.name}
  }
);

watch(searchTerm, (newValue) => {
  // Clear the previous timer if the user is still typing
  clearTimeout(debounceTimeout.value);

  // Set a new timeout to apply the filter after 1 second
  debounceTimeout.value = setTimeout(() => {
    filteredElements.value = filteredElementsActive();
    console.log("Filtered elements updated with searchTerm:", newValue);
  }, 100); // 1-second delay
});
// compute filters
// Create a computed property to filter organisms based on searchTerm
function filteredElementsActive() {
//const filteredElements = computed(() => {
  // Filtrado basado en el término de búsqueda (incluyendo ID)
  const searchLower = searchTerm.value.toLowerCase();
  let filtered = elements.value.filter(element => {
    const elementLower = element.name.toLowerCase();
    return elementLower.includes(searchLower) && element[props.parent_name];
  });

  
  //if (props.parent_name==='specie'){debugger}
  // filter by parent
  if (searchLower.length>0 && props.parent_name==='specie'){
    /* Filtrar dada la especie, en caso network */
  let filtered_parent = elements.value.filter(element => {

    if (props.parent_name){
      const parent_value = element[props.parent_name];
      if (parent_value){
        const elementLower = parent_value[props.filter_field].toLowerCase();
          return elementLower.includes(searchLower);

      }
      else {
        return false
      }
    }
    else{
      return false
    }
  });
  const map = new Map(filtered.map((item, index) => [item.id, item]));

  const map_parent = new Map(filtered_parent.map((item, index) => [item.id, item]));

// Perform union
const unionMap = new Map(map); // Start with all entries from map1

for (const [key, value] of map_parent) {
  unionMap.set(key, value); // Adds new entries or overwrites existing ones from map1 with map2's values
}  

 filtered = Array.from(unionMap.values());
 const names = filtered.map(item => item.name.toLowerCase());
 const uniqueSet = new Set(names);
 const names2 = [...uniqueSet]
 emit('to_next_filter', names2)

} // end filter parent=specie


// start_filter :: parent = metabolic_network
if (props.parent_name==='metabolic_network' &&  props.filters_list.length>0){
  /*
  Filtrar cuando parent es metabolic_network
  Use: filters_list
  */
  //debugger
  //if (searchLower.length===0){
  //  filtered = [];
  //}

  let filtered_parent = elements.value.filter(element => {
    if (props.parent_name){
      const parent_value = element[props.parent_name];
      if (parent_value){
        const elementLower = parent_value[props.filter_field].toLowerCase();
        const containsWord = props.filters_list.some(str => elementLower.includes(str));
        return containsWord;}
      else {
        return false
      }
    }
    else{
      return false
    }  

  })

  const map = new Map(filtered.map((item, index) => [item.id, item]));

  const map_parent = new Map(filtered_parent.map((item, index) => [item.id, item]));

// Perform union
const innerJoin = new Map();

for (const [key, value] of map) {
  if (map_parent.has(key) && map_parent.get(key) === value) {
    innerJoin.set(key, value);
  }
}


 filtered = Array.from(innerJoin.values());

} // end filter parent=metabolic network



  if (filtered.length === 0) {
  return {};
  } else {
  const filteredDic = filtered.reduce((acc, item) => {
    acc[item.id.toString()] = item;
    return acc;
  },{});
  
  console.log("returning filteredDict", filteredDic)
  updateSelectorsAfterRender()
  console.log("LISTA PREFILTERED", props.name, filtered, filteredDic)
  return filteredDic;
}
}

watch(filteredElements, ()=>{
  console.log("Running watcher->")
  updateSelectorsAfterRender()
})

// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

console.log("URL",useConfigStore.baseUrl)

function goToCreatePage() {
  router.push({ name: 'CreateFromLibrary' });
}
let watchParent: (() => void) | null = null;
onMounted(() => {
  fetchElements();
  watchParent = watch(
  ()=>props.parent,
  ()=>{
    filteredElements.value = filteredElementsActive()
    },
    { immediate: true, deep: true }
)  
  
});


const currentPage = ref(1);
const pageSize = 2; // Ajusta el tamaño de página según tus necesidades

const paginatedElements = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  return filteredElements.value.slice(start, start + pageSize);
});

const totalPages = computed(() => Math.ceil(filteredElements.value.length / pageSize));

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;  

  }
}

async function fetchElements() {
  loading.value = true;
  try {
    const response = await api.get(props.endpoint);
    elements.value = response.data;
    filteredElements.value = filteredElementsActive() 
    console.log("FIlTERED ELEMENTS",filteredElements.value.length)

    emit('send_elements', elements.value)
  } catch (error) {
    error.value = true;
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}
defineExpose({
    selectItem,
    selected,
})

</script>
<style scoped>
.archive-block {
  
}

.ok{
  color:green;
}

.fail{
  color: red;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;  /* Or specify a height if needed */
  justify-content: space-between; /* Ensures footer button is at the bottom */
  padding: 10px;
  
    
}

.library-content {
  flex: 1; /* Allows content to grow and push the button down */
  overflow-y: auto; /* Adds scroll if the content is too long */
}

.footer {
  align-self: center; /* Centers the button horizontally */
  margin-top: 10px;
}

.specie-selection {
  margin-left: -5px;
  width: 100%;
}

.radio-button {
  display: flex;
  align-items: flex-start;
  margin-top: -50px;
}

.huey[type="radio"] {
  opacity: 0;
  position: absolute;
  width: 0;
  height: 0;
}


.library-content label {
  margin-right: 10px;
}

h2,h3,h4 {
  color: #333;
}

ul {
  list-style-type: none;
  padding-left: 0%;
  font-size: 12px;
  display: flex;
  
}

.create-button {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 30%;
}

.create-button:hover {
  background-color: #333;
}

.clear-button {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 10%;
}

.create-button:hover {
  background-color: #333;
}

.btn {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
}

.list-select-item {
  display: flex;
  flex-direction: column;
  
}

.list-select-item li {
  display: flex; /* Make the li element a flex container */
  align-items: center; /* Align elements vertically within the li */
}

.list-select-item li input {
  margin-right: 10px; /* Add a margin to the right of the radio button */
}

.download-icon {
  margin-left: 7px;
  font-size: 18px;
  color: green;
  justify-content: center;

}

.select-item {
    padding: 0;
    margin: 0;
    list-style: none; /* Quita los puntos o números */
    background-color: fuchsia;
    display: flex;
    flex-direction: column;
  }

  .select-item li {
    margin: 0;
    padding: 0;
  } 

.highlighted {
  background-color: #cccccc;
  /*width: 50%;*/
  padding: 4px;
  border-radius: 4px;
  text-align: center;
}

.selected-count {
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.search-input {
  display: flex;
  /*width: 100%;  Ocupa todo el ancho disponible */
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
}

.search-input:focus {
border-color: #646464;
outline: none;
box-shadow: 0 0 0 3px #d2d2d2;
}

.archive-modal{
  display: flex; /* Enables flexbox for positioning */
  flex-direction: column; /* Stacks elements vertically */
  align-items: flex-end; /* Aligns items to the right end */
  justify-content: center;
}

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  background-color: #000000;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}


.list-item {
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center content */
  justify-content: space-between; /* Create space between radio button and download link */
  padding: 10px;
}

.list-item .download-link {
  margin-right: auto; /* Push the link to the left */
}

</style>