<template>
<Menu></Menu>
<router-view></router-view> 
</template>
<script setup lang="ts">
import { computed, ref,watch, onMounted } from 'vue';
import Menu from './components/Menu.vue';
import { useAuthStore } from './stores/authStore';
import './assets/main.css' // Adjust the path to where you saved App.css or main.css

const isMenuOpen1 = ref(false);
const isMenuOpen2 = ref(false);
const isMenuOpen3 = ref(false);

const toggleMenu1 = () => {
  isMenuOpen1.value = !isMenuOpen1.value;
  isMenuOpen2.value = false; // Close the other menu if open

  /*setTimeout(() => {
    isMenuOpen1.value = false;
  }, 10000 );*/

};

const toggleMenu2 = () => {
  isMenuOpen2.value = !isMenuOpen2.value;
  isMenuOpen1.value = false; // Close the other menu if open

  /*setTimeout(() => {
    isMenuOpen2.value = false;
  }, 10000);*/

};

const toggleMenu3 = () => {
  isMenuOpen3.value = !isMenuOpen3.value;
  isMenuOpen1.value = false; // Close other menus if open
  isMenuOpen2.value = false;

  setTimeout(() => {
    isMenuOpen3.value = false;
  }, 10000);
};

const selectOption = (option: string) => {
  // Add your logic here, e.g., navigate to a different page, trigger an action, etc.
  isMenuOpen1.value = false; // Close the menu after selecting an option
  isMenuOpen2.value = false;
};
const baseUrl = import.meta.env.VITE_APP_BASE_URL;


console.log("Base URL",baseUrl);

// Restore authentication state
const authStore = useAuthStore();
authStore.restoreAuth();
</script>
<style>

</style>
