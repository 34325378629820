<template>
    <div class="container">
          <div class="header" @click="toggle">
            <slot name="header">
              <h3>{{ title }}</h3>
            </slot>
            <span class="arrow">{{ isOpen ? "▲" : "▼" }}</span>
        </div>
        <div v-if="isOpen" class="content">
          <slot name="body">
            <form @submit="handlePostOrganism">            
                <div class="form-group">
                <label>
                    Name
                </label>
                <div class="archive-parent">
                <SelectSpecie 
                :parent_list="parent_list" 
                :parent_name="parent_name" 
                v-model="filteredText"
                @send_parent="captureParent">
                </SelectSpecie>
              </div>

                </div>
                <div class="form-group">
                <label>
                    Condition 
                </label>
                <input
                class="form-control" 
                v-model="condition"
                type="text">
            </div>
            <div class="action-group">
                <button 
                :disabled="!isEnabled" 
                :class="{ 'action-button enabled-btn': isEnabled, 'action-button disabled-btn': !isEnabled }" 
                type="submit">Submit</button>
            </div>  
            <!-- <p>This is the default body of the new-organism.</p>-->
          </form>
          </slot>
          <footer class="new-organism-footer">
          <slot name="footer">
           <!-- <button class="action-button" @click="close">Close</button>-->
          </slot>
        </footer>

        
      </div>
    </div>
  </template>
  <script setup lang="ts">
  import { defineProps, defineEmits, PropType } from 'vue';
  import { ref, reactive,onMounted, computed, watch } from 'vue';
  import axios from 'axios';
  import { useAuthStore } from '@stores/authStore';
  import { useRouter } from 'vue-router';
  import { useConfigStore } from '@stores/configStore';
  import SelectSpecie from './SelectSpecie.vue';

  const parentId = ref(null);
  const parent = ref({});
  const filteredText = ref('');
  //import { Element, OrganismData } from '../interfaces/organism-data';
  const router = useRouter();
  const props = defineProps({

    title: {
      type: String,
      default: 'new-organism Title',
    },
    items:
    {
        type: Object,
        default: 
        {
          transcriptomes: null,
          mediums: null,
          'metabolic network': null,
        },
    },
    parent_name:{
      type: String,
      default:''
    },
    parent_list: {
      type:Object,
      default:[]
    }    
  });


const emit = defineEmits<{
  (e: 'created', object: Object): void;
  (e: 'send_parent', object:Object):void;
  (e:'close'):void
}>();

const isEnabled =ref(false);
const isOpen = ref(true);

onMounted(() => {
  watch(
    () => [props.items.transcriptomes, props.items.mediums, props.items['metabolic network']],
    ([transcriptomes, mediums, metabolic_network]) => {
      if (transcriptomes && mediums && metabolic_network) {
        isEnabled.value = true;
      }
    },
    { immediate: true }
  );
});

  function captureParent(newparent:Object){
    parentId.value = newparent.id
    parent.value = newparent
    emit('send_parent', newparent)
  }


  function toggle() {
    if (props.items["metabolic network"]){
    isOpen.value = !isOpen.value;
    setBaseOrganism()}
  }


  const organism_name = computed(()=>{
    if (organism.value===''){
      return props.items["metabolic network"].parent 
    } else {
      return organism.value
    }
  })

  function setBaseOrganism(){
    organism.value = props.items["metabolic network"].parent 
  }
  const configStore = useConfigStore();
  const authStore = useAuthStore();

  const token = authStore.token;


  
  const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Authorization':`Bearer ${token}`
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.request.use(request => {
  console.log("Starting Request", request);
  return request;
});

axios.interceptors.response.use(response => {
  console.log("Response:", response);
  return response;
}, error => {
  console.error("Error Response:", error);
  debugger
  return Promise.reject(error);
});


  const organism = ref('');
  const condition = ref('');


async function createNewSpecie(){
  const data = new FormData();
  data.append('name', filteredText.value);
  data.append('description', '');
  await api_sp.post("/api/specie/species",data)  
  .then((response) => {
    parentId.value = response.data.id
  })
  .catch((error) => {
    console.error('Error:', error.response ? error.response.data : error.message);
    emit('error_signal', {"error":true, "message":(error.response ? error.response.data : error.message).error})
  });
}


  const dataDict = computed(() => {
    return {
      'transcriptomes_fk': props.items.transcriptomes.id,
      'medium_fk': props.items.mediums.id,
      'gem_fk': props.items["metabolic network"].id,
      'organism': organism_name.value,
      'condition': condition.value,
      'zip_file': null
      }
  });


  const createdOrg = ref({});


  const response_specie = ref(true)

  const error = ref('');
  async function handlePostOrganism (event:Event){
    event.preventDefault();
    
    try {

      if (props.parent_name==='specie'&& parent.value===null){
          /* create new specie and get the id for this new object */
          await createNewSpecie().then((response)=>{
            console.log("****Response create specie****", response)
            if(response.status_code===200){
              response_specie.value = true
              data.name = response.data.name
            } 
          }).catch((error)=>{
            response_specie.value = false;
          })
        }


      const data = dataDict.value;
      //const response = await api.post('/api/organisms/organisms_free', data);
      const response = await api.post('/api/organisms/organisms_free', data, { timeout: 10000 })  
            .then((response) => {
              console.log("Response", response.data)
              emit('created',  response.data);
              return response
            })
              .catch((error) => {
                console.error('Error:', error.response ? error.response.data : error.message);
              //emit('error_signal', {"error":true, "message":(error.response ? error.response.data : error.message).error})
            });
      router.push("/organism-task")      
  } catch (error) {
   
    if (axios.isAxiosError(error)) {
      if (error.response) {
        if (error.response.status >= 300 && error.response.status < 400) {
          // Handle redirect response here
          console.log('Redirect Detected. Location:', error.response.headers.location);
          // Optionally, take some action based on the redirect URL
        } else {
          // Handle other non-redirect errors
          console.log('Error Status:', error.response.status);
          console.log('Error Data:', error.response.data);
        }
      } else if (error.request) {
        console.log('No Response Received:', error.request);
      } else {
        console.log('Axios Setup Error:', error.message);
      }
    } else {
      console.log('Unexpected Error:', error);
    }
  }


  }

  function actionData(event){
    console.log(props.items)
    debugger
  }
  
  
  const close = () => {
    emit('close');
  };
  </script>
  
  <style scoped>
.container {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background-color: #f3f3f3;
  cursor: pointer;
  width: 100%;
}

.arrow {
  font-size: 1.2em;
}

.content {
  padding: 10px;
  width: 100%;
}


.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative; /* Enable positioning for tooltip */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.action-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}

.action-button:hover {
  background-color: #333;
}

.action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
  .new-organism-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #00000080;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .new-organism-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .new-organism-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .new-organism-body {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .new-organism-footer {
    display: flex;
    justify-content: flex-end;
  }

  .table {
    /*text-align: right;
    border-collapse: collapse;
    width: 100%;*/
  }

  th {
    text-align: right;
  }

  /*thead {
    text-align: center;
  }*/

  /*th, td {
    border: 1px solid #ddd;
    padding: 8px;
  }*/

 span {
    background-color: #afafaf;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    text-align: left;
  }

  .enabled-btn {
  background-color: #4CAF50; /* Green */
  color: white;
  cursor: pointer;
}

/* Style for disabled button */
.disabled-btn {
  background-color: #ccc; /* Gray */
  color: #666;
  cursor: not-allowed;
}
  </style>