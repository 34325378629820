<template>
  <div class="login-container">
    <h2>Login Pheflux</h2>
    <form @submit.prevent="handleLogin">
      <div class="form-group">
        <label for="username" >Username:
          <span class="tooltip">
              Enter your username
          </span>
        </label>
        <input 
          id="username" 
          v-model="username" 
          type="text" 
          class="form-control" 
          placeholder="Enter your username" 
          :class="{ 'is-invalid': error }"
          @click="clearError('username')"
        />
      </div>
      <div class="form-group">
        <label for="password">Password:
          <span class="tooltip">
              Enter your password 
          </span>
        </label>
        <input 
          id="password" 
          v-model="password" 
          type="password" 
          class="form-control" 
          placeholder="Enter your password" 
          :class="{ 'is-invalid': error }"
          @click="clearError('password')"
        />
      </div>
      <div class="action-group">
        <button type="submit" class="login-button">Login</button>
        <button @click="goToRegisterPage" href="#" class="register-link login-button">Register</button>
        <a href="/password-recovery" class="recover-password">Recover Password</a>

      </div>
      <p v-if="error" class="error-message"><font-awesome-icon icon="fa-exclamation-triangle" /> {{ error }}</p>
      <transition name="fade">
        <p v-if="success" class="success-message">
          <font-awesome-icon icon="fa-check-circle" class="success-icon" /> 
          Successful login! Redirecting...
        </p>
      </transition>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useAuthStore } from '@stores/authStore';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@stores/configStore';
import axios from 'axios';


export default defineComponent({
  setup() {
    const clearErrors = () => {
      username.value = '';
      password.value = '';
      error.value = '';
    }

    const username = ref('');
    const password = ref('');
    const error = ref('');
    const router = useRouter();

    const success = ref(false);

    const configStore = useConfigStore();
    const authStore = useAuthStore();
    const api = axios.create({
      baseURL: configStore.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      });
    console.log("Baseurl", )
  
    const clearError = (fieldName: string) => {
      if (fieldName === 'username') {
        username.value = '';
      } else if (fieldName === 'password') {
        password.value = '';
      }
      error.value = '';
    };

    const handleLogin = async () => {
      try {
        await authStore.login(api, username.value, password.value);
        success.value = true;
        setTimeout(() => {
          router.push('/dashboard'); // Redirect to dashboard after login
        }, 1500);
        
      } catch (err) {
        error.value = 'Login failed. Please check your credentials.';
      }
    };

    const goToRegisterPage = () => {
      router.push('/register'); // Navigate to the registration page
    };

    /*const goToRegisterPage = () => {
      router.push('/register'); // Navigate to the registration page
    };*/

    return {
      username,
      password,
      error,
      handleLogin,
      goToRegisterPage,
      clearError,
      success
    };
  },
});
</script>

<style scoped>
.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative; /* Enable positioning for tooltip */
}

.tooltip {
  position: absolute;
  top: 100%; /* Position below the form group */
  left: 0;
  background-color: #95f1ff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px #00000026; /* Add a shadow */
  font-size: 14px;
  color: #333;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, transform 0.3s ease; /* Add transform transition for animation */
}

.form-group:hover .tooltip {
  opacity: 1;
  visibility: visible;
  transform: translateY(-10px); /* Move the tooltip slightly upwards */
}

.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {

}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.is-invalid {
  border-color: red;
}

.form-control:focus {
  border-color: #a2a2a2;
  outline: none;
  box-shadow: 0 0 0 3px rgba(129, 129, 129, 0.25);
}

.action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.error-icon {
  color: red;
  margin-left: 5px;
}

.login-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #333;
}

.register-link {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-link:hover {
  background-color: #333;
}

.error-message {
  color: red;
  margin-top: 16px;
  text-align: center;
}

.fade-enter-active,
.fade-leave-active {
  transition: background-color 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  background-color: transparent; /* Color inicial transparente */
}

.success-message {
  color: green; /* Color del texto para un contraste claro */
  text-align: center;
}
</style>
