import { createApp } from 'vue'
import { createPinia } from 'pinia';
import './style.css';
import App from './App.vue';
import router from './router';

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCoffee, faSheetPlastic } from '@fortawesome/free-solid-svg-icons';
import 
{ 
    faUser, 
    faAddressBook, 
    faHome,  
    faListUl, 
    faTasks, 
    faFlask,
    faFolderPlus,
    faPlusSquare,
    faExclamationTriangle,
    faCheckCircle,
    fas,
    faChevronLeft, 
    faChevronRight,
    faEye, 
    faEyeSlash,
    faQuestion,
    faAsterisk,
    faFilter,
    faList,
    faDownload,
    faBacteria,
    faSheetPlastic,

  
} from '@fortawesome/free-solid-svg-icons';
//******* 

// Add the icons to the library
library.add(faCoffee);
library.add(
    faUser, 
    faAddressBook, 
    faHome, 
    faListUl, 
    faTasks, 
    faFlask,
    faFolderPlus,
    faPlusSquare,
    faExclamationTriangle,
    faCheckCircle,
    fas,
    faChevronLeft, 
    faChevronRight,
    faEye, 
    faEyeSlash,
    faQuestion,
    faAsterisk,
    faFilter,
    faList,
    faDownload,
    faBacteria,
    faSheetPlastic,
);
//*******

const pinia = createPinia();

const app = createApp(App)

app.component('font-awesome-icon', FontAwesomeIcon); //font awesome

app.use(pinia);
app.use(router);
app.mount('#app');
