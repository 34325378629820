import { defineStore } from 'pinia';
import axios, { AxiosInstance } from 'axios';

interface AuthState {
    token: string | null;
    username: string | null;
    password: string | null;
    isAuthenticated: boolean,
}

async function obtenerToken(api:AxiosInstance, username: string | null , password: string | null) {
    try {
      const response = await api.post('/api/login', {

        username: username,
        password: password,
      });
  
      return response.data.token; // Suponiendo que el token se devuelve en la propiedad 'token' de la respuesta
    } catch (error) {
      console.error('Error al obtener el token:', error);
      throw error; // Propagar el error para que sea manejado en otro lugar
    }
  }

export const useAuthStore = defineStore('auth', {
    state: (): AuthState => ({
        token: localStorage.getItem('authToken'),
        username: localStorage.getItem('username'),
        password: null,
        isAuthenticated: false,
    }),
    actions: {
        async login(api:AxiosInstance, username: string, password: string): Promise<void> {
            // Simulate an API call
            if (username != '' && password != '') {
                // crear llamada a axios.post login
                const token = await obtenerToken(api, username, password);
                this.isAuthenticated = true
                //Terminando llamada a axios.post login

    
                this.token = token;
                this.username = username;
                const loginTimestamp = new Date().getTime().toString()               
                localStorage.setItem('authToken', token);
                localStorage.setItem('user', username);
                localStorage.setItem('loginTimestamp', loginTimestamp)
                localStorage.setItem('isAuthenticated', 'true');
            } else {
                throw new Error('Invalid credentials');
            }
        },
        clearAuthCache() {
            this.isAuthenticated = false
            localStorage.removeItem('loginTimestamp')            
            // clear other cache data if needed
          },
          setAuthCacheTimeout() {
            setTimeout(() => {
              this.clearAuthCache()
              console.log("Auth cache cleared after 30 minutes.")
            }, 30 * 60 * 1000) // 30 minutes in milliseconds
          }, 
          checkAuthCacheExpiration() {
            const loginTimestamp = localStorage.getItem('loginTimestamp')
            if (loginTimestamp) {
              const currentTime = new Date().getTime()
              const elapsedTime = currentTime - parseInt(loginTimestamp, 10)
              const thirtyMinutes = 30 * 60 * 1000
      
              if (elapsedTime > thirtyMinutes) {
                this.clearAuthCache()
                console.log("Auth cache cleared after 30 minutes of inactivity.")
              }
            }
          },                 
        logout() {
            this.token = null;
            this.username = null;
            localStorage.removeItem('authToken');
            localStorage.removeItem('username');
            // Clear from localStorage
            localStorage.removeItem('isAuthenticated');
        },
        restoreAuth() {
          // Check localStorage on app load
          const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
          const username = localStorage.getItem('user');
          if (isAuthenticated && username) {
              this.token = localStorage.getItem('authToken');
              this.isAuthenticated = true;
              this.username = username;
          }
      },        
    }
});
