/*
Este contenedor toma un formulario de dos campos:
- nombre
- archivo
Y lo envía como post,
toma lo que retorna y lo agrega al componente padre (emit)
*/

<template>
    <div 
    v-if="isOpen" 
    class="modal-overlay" 
    @dragover.prevent
    @drop.prevent="handleDrop"   
    @click.self="close">
        <div class="modal-content">
            <h2>New {{ name }}</h2>

      <form @submit.prevent="handlePostArchive">
    <div>
      <label for="archive">
        <font-awesome-icon icon="cloud-upload-alt" />
        Upload File:
      </label>
      <strong>(20mb)</strong>
      <input
        ref="fileInput"
        id="file"
        type="file"
        @change="handleFileUpload"
        required
        style="display: none;"
      />
      <div
        class="drag-drop-area"
        @click="triggerFileInput"
      >
        Drag and drop a file here, or click to select one
      </div>
      <p v-if="filepath">Selected file: {{ filepath.name }}</p>
    </div>

    <div class="action-group">
      <button class="action-button" type="submit">Create</button>
      <button class="action-button" @click="close">Close</button>
    </div>
  </form>
    </div>
</div>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, PropType } from 'vue';
import { ref, reactive,onMounted, computed, watch } from 'vue';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@stores/configStore';
import SelectParent from './SelectParent.vue';

const formData = ref({
      name: '',
      filepath: ''
    });
const parentId = ref(null);
const filteredText = ref('');


const props = defineProps({
    isOpen: {
      type: Boolean,
      required: true,
    },    
    name: {
      type: String,
    },
    endpoint: {
      type: String,
    },
    parent_name:{
      type: String,
      default:''
    },
    parent_list: {
      type:Object,
      default:[]
    },
    parent :{
      type:Object,
      default:null
    }    
});

const emit = defineEmits<{
  (e:'created', object: Object): void;
  (e:'close'):void;
  (e:'error_signal', object:Object):void
}>();



function captureParentId(parent_id:Number|null){
  parentId.value = parent_id
}

const error = ref('');
const loading = ref(false);

const configStore = useConfigStore();
const authStore = useAuthStore();
const filepath = ref<File | null>(null); 
const fileInput = ref<HTMLInputElement | null>(null);

function handleDrop(event: DragEvent) {
  if (event.dataTransfer && event.dataTransfer.files.length > 0) {
    filepath.value = event.dataTransfer.files[0];
  }
}

function triggerFileInput() {
  console.log("Trigger dialog")
  if (fileInput.value) {
    fileInput.value.click();
  }
}


function handleFileUpload(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      filepath.value = target.files[0];
    }
  }


  const token = authStore.token;

  /*
          'Content-Type': 'application/json',

  */

  const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization':`Bearer ${token}`
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.request.use((config) => {
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});    

const api_sp = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Authorization':`Bearer ${token}`
  },
  });
// Set the Authorization header for all requests if token is available
api_sp.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});





const response_specie = ref(true)

async function handlePostArchive (event:Event){
  try{
        const data = new FormData();
        data.append('filepath', filepath.value);
        data.append("source", null);
        const filename = filepath.value.name.replace(/\.[^/.]+$/, "");
        data.append('name', filename);
        data.append(props.parent_name, props.parent.id)


    if(props.endpoint!==''){

     await api.post(props.endpoint, data)  
     .then((response) => {
      emit('created',  response.data);
    })
      .catch((error) => {
        console.error('Error:', error.response ? error.response.data : error.message);
      emit('error_signal', {"error":true, "message":(error.response ? error.response.data : error.message).error})
    });
    
    }
        else{
            console.log("no endpoint provided")
        }


      //console.log("RESULT =>", response);

      close()
      //return result;
    }catch (err){
      error.value = 'Post failed. Please check.';
    }
  }

    
  const close = () => {
    emit('close');
  };
</script>
<style>
.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  position: relative; /* Enable positioning for tooltip */
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}


.search-input {
  display: flex;
  /*width: 100%;  Ocupa todo el ancho disponible */
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #333;
  box-sizing: border-box;
  padding: 7px;
  width: 100%;
}

.search-input:focus {
border-color: #646464;
outline: none;
box-shadow: 0 0 0 3px #d2d2d2;
}

.action-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.action-button:hover {
  background-color: #333;
}

.action-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  .modal-content {
    background-color: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
  }
  
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .modal-body {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
  }

  .drag-drop-area {
  margin-top: 10px;
  padding: 20px;
  border: 2px dashed #7e7e7e;
  text-align: center;
  cursor: pointer;
}
</style>