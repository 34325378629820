<template>
  <div class="container organism-task-page">
    <div class="left-column">
      <div class="slider">
        <h2><font-awesome-icon icon="fa-check-list fa-flask" /> Select Organisms</h2>
      <organism-list
        :organisms="organisms"
        v-model:selectedOrganisms="selectedOrganisms"
      />
      </div>
    </div>

    <!-- Right Column -->
    <div class="right-column">
      <div class="fixed-content">
        <h2><font-awesome-icon icon="folder-plus" /> Create New Task</h2>
      <create-task
        :selectedOrganisms="selectedOrganisms"
        @taskCreated="handleTaskCreated"
      />
      </div>
      </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from 'vue';
import axios from 'axios';
import OrganismList from './OrganismList.vue';
import CreateTask from './CreateTask.vue';
import { useAuthStore } from '@stores/authStore';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@stores/configStore';

interface Organism {
  id: number;
  organism: string;
  date_creation: Date
}

const organisms = ref<Organism[]>([]);
const selectedOrganisms = ref<Organism[]>([]);


const configStore = useConfigStore();
  const authStore = useAuthStore();

  const token = authStore.token;


  
  const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
    'Authorization':`Bearer ${token}`
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axios.interceptors.request.use(request => {
  console.log("Starting Request", request);
  return request;
});

axios.interceptors.response.use(response => {
  console.log("Response:", response);
  return response;
}, error => {
  console.error("Error Response:", error);
  debugger
  return Promise.reject(error);
});



async function fetchOrganisms() {
  try {
    const response = await api.get('/api/organisms/organisms');
    organisms.value = response.data;
  } catch (error) {
    console.error('Error fetching organisms:', error);
  }
}

function handleTaskCreated() {
  // After task is created, you can reset the selection or redirect the user
  selectedOrganisms.value = [];
}

onMounted(() => {
  fetchOrganisms();
});
</script>

<style scoped>
.organism-task-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}


.task-form {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}




.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full screen height */
}

.left-column,
.right-column {
  width: 50%; /* Adjust widths as needed */
  height: 100%;
  overflow: hidden;
  position: relative;
}

.left-column {
  display: flex;
  justify-content: flex-end; /* Align content to the right */
  align-items: center; /* Center content vertically */
  padding-right: 10px; /* Optional padding */
}

.slider {
  overflow-y: auto; /* Enable scrolling */
  height: 100%;
  width: 100%;
}

.list-item {
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  cursor: pointer;
}

.item-header {
  font-weight: bold;
}

.item-details {
  margin-top: 5px;
}

.right-column {
  display: flex;
  justify-content: flex-start; /* Attach to left-center */
  padding-left: 10px;
}

.fixed-content {
  position: sticky; /* Keep it fixed at the top */
  top: 0;
  background: #fff;
  padding: 20px;
  width: 100%;
}

</style>
