<template>
<p class="selected-count" :class="{ selected: selectedCount > 0 }">Selected: {{ selectedCount }} organisms</p>
  <div class="organism-list">
    <div v-if="!loading && organisms.length > 0" class="organism-table">

      <div v-for="organism in paginatedOrganisms">
      <ul>
          <!--  <thead v-if="organism.id===first_element_id">
      <tr>
          <th><input type="checkbox" class="checkbox checkbox-all" title="click to select all the elements shown" /></th>
          <th>ID</th>
          <th>Organism Name</th>
          <th>Condition</th>
          <th>Expand</th>
        </tr>
              
      </thead>      -->    
          <div :key="organism.id" class="organism-row">
            <div class="head-item-organism">
              <div class="control-head">
              <input
                  type="checkbox"
                  :value="organism"
                  :checked="isSelected(organism)"
                  @change="toggleSelection(organism)"
                  class="checkbox left"
                /> 
                <strong class="check">SELECT ITEM</strong>
                <span :class="['arrow', { right: true }]" @click="toggleOrganism(organism)">{{ isOpen[organism.id] ? "◄":"►"  }}</span>
              </div>
                <ul>
                  <li>
                    <strong>organism:</strong> {{ organism.organism }}
                  </li>
                  <li>
                    <strong>condition:</strong> {{ organism.condition }}
                  </li>
                </ul>


        <!-- Expanded row for additional organism information -->
        <div  v-if="isOpen[organism.id]===true"  :key="`details-${organism.id}`" class="org-extra-info-row">
                  <!-- Information about archives -->
                  <table  class="table-info">
                    <tbody>
                      <tr>
                        <td><strong>Tasks:</strong></td>
                        <td>
                          <ul>
                            <li v-for="task in organism.tasks">{{ task.id }}</li>
                          </ul>
                        </td>
                        <td>
                          <ul>
                            <li v-for="task in organism.tasks">
                              <a 
                                  v-if="task.result_file" 
                                  :href="get_task_url(task)" 
                                  target="_blank" 
                                >
                                <font-awesome-icon icon="download" />
                              </a>       
                              </li>                       
                          </ul>
                        </td>


                      </tr>
                      <tr>
                        <td><strong>Specie:</strong></td>
                        <td v-if="organism.gem_fk">
                          <div>{{ organism.gem_fk.specie.name }}</div>
                        </td>
                        <td></td>
                      </tr>

                      <tr>
                        <td><strong>Metabolic Network:</strong></td>
                        <td v-if="organism.gem_fk">
                          <div>{{ organism.gem_fk.name }}</div>
                        </td>
                        <td>
                          <a 
                v-if="organism.gem_fk.filepath" 
                :href="get_url(organism.gem_fk)" 
                target="_blank" 
              >
              <font-awesome-icon icon="download" />
            </a>

                        </td>

                      </tr>

                      <tr>
                        <td><strong>Medium:</strong></td>
                        <td v-if="organism.medium_fk">
                          <div>{{ organism.medium_fk.name }}</div>
                        </td>
                        <td>
                          <a 
                v-if="organism.medium_fk.filepath" 
                :href="get_url(organism.medium_fk)" 
                target="_blank" 
              >
              <font-awesome-icon icon="download" />
            </a>

                        </td>

                      </tr>

                      <tr>
                        <td><strong>Transcriptomes:</strong></td>
                        <td v-if="organism.transcriptomes_fk">
                          <div>{{ organism.transcriptomes_fk.name }}</div>
                        </td>
                        <td>
                          <a 
                v-if="organism.transcriptomes_fk.filepath" 
                :href="get_url(organism.transcriptomes_fk)" 
                target="_blank" 
              >
              <font-awesome-icon icon="download" />
            </a>
                        </td>

                      </tr>
                    </tbody>
                  </table>
                  <!-- End of archive info -->
      </div>                
            </div>
          </div>
      </ul>
    </div>

    <p v-if="loading" class="loading-message">Loading...</p>
    <p v-if="!loading && organisms.length === 0" class="empty-message">No organisms found.</p>
    <div v-if="totalPages > 1" class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
      <span class="pagination-current">{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
    </div>
  </div>
  </div>
</template>
<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';
import {integerToUUID} from '@tools/uuid';
import { isParameter } from 'typescript';


function get_url(item:Element) {
  const url = configStore.baseUrl;
  const link = `${url}/media${item.filepath}`
  //debugger;
  return link;
}

const first_element_id = ref(0);

const selectedCount = computed(() => {
  return props.selectedOrganisms.length;
});

const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
  baseURL: configStore.baseUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  });
// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
const isOpen = ref({});

function toggleOrganism(organism:Organism) {
  
  if (isOpen.value[organism.id]===undefined){
    isOpen.value[organism.id] = true
  } else {
    isOpen.value[organism.id] = !isOpen.value[organism.id] 
  }
  }


interface Organism {
  id: number;
  organism: string;
  condition: string;
  gem_fk:Object;
  medium_fk:Object;
  transcriptomes_fk:Object
}

const props = defineProps<{
  selectedOrganisms: Organism[];
}>();

const emit = defineEmits(['update:selectedOrganisms']);

const organisms = ref<Organism[]>([]);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = 3; // Set page size

const totalPages = computed(() => Math.ceil(organisms.value.length / pageSize));

const paginatedOrganisms = computed(() => {
  const start = (currentPage.value - 1) * pageSize;
  return organisms.value.slice(start, start + pageSize);
});

async function fetchOrganisms() {
  loading.value = true;
  try {
    const response = await api.get('/api/organisms/organisms');
    organisms.value = response.data;
    first_element_id.value = organisms.value[0].id
  } catch (error) {
    console.error('Error fetching organisms:', error);
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}

function isSelected(organism: Organism): boolean {
  return props.selectedOrganisms.some(selected => selected.id === organism.id);
}

function toggleSelection(organism: Organism) {
  let newSelection;
  if (isSelected(organism)) {
    newSelection = props.selectedOrganisms.filter(selected => selected.id !== organism.id);
  } else {
    newSelection = [...props.selectedOrganisms, organism];
  }
  emit('update:selectedOrganisms', newSelection);
}

/*function selectAll(event: { target: { checked: any; }; }) {
  const isChecked = event.target.checked; // Obtener el estado del checkbox principal
  const newSelection = isChecked
    ? paginatedOrganisms.value.slice() // Copiar todos los organismos si se selecciona
    : []; // Vaciar la selección si se deselecciona

  emit('update:selectedOrganisms', newSelection);
}*/

function get_task_url(task:Task) {
  const url = configStore.baseUrl;
  return `${url}/media${task.result_file}`
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}
onMounted(() => {
  fetchOrganisms();
});
</script>
<style scoped>
.control-head{
  display: flex;
  align-items: left; /* Centers vertically if needed */ 
}


.head-item-organism{
  width: 100%;
}
.arrow {
  margin-left: auto; /* Pushes the arrow to the far right */
  margin-right: 0; /* Optional, reset margin if needed */
}

.arrow.right {
  float: right;
}

.org-extra-info{
  z-index: 50;
}
.organism-table {
  border-collapse: collapse;
  width: 100%;
  border: none; /* Remove default border */
  /*box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);  Subtle shadow */
}

.organism-table th,
.organism-table td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left; /* Align text left by default */
}

.organism-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.organism-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.organism-table tr:hover {
  background-color: #e8e8e8; /* Highlight on hover */
  cursor: pointer; /* Indicate clickable elements */
}

.organism-table th:first-child,
.organism-table td:first-child {
  /* Style the first column (checkbox) */
  padding-left: 0; /* Remove left padding */
}

.organism-table th:last-child,
.organism-table td:last-child {
  /* Style the last column */
  padding-right: 0; /* Remove right padding */
}

.selected-count {
  font-weight: bold;
  margin-bottom: 10px;
  color: #ccc;
}

.selected-count.selected {
  color: #000000; /* Color when at least one element is selected */
}


.organism-list ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}



.organism-item {
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
}

.organism-item:last-child {
  border-bottom: none;
}

.checkbox {
  margin-right: 10px;
  margin-bottom: 15px;
  width: 20px;
  height: 20px;
  transform: scale(1.2);
}

.check {
  background-color: #dbdbdb;
  padding: 4px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.organism-name {
  font-size: 16px;
  color: #333;
}

.loading-message,
.empty-message {
  font-size: 16px;
  color: #555;
  text-align: center;
  margin-top: 20px;
}

.pagination {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button {
  background-color: #000000;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}

.organism-row {
  display: flex;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 8px 0;
  padding: 8px;
}

.table-info{
  width: 100%;
}

.organism-list{
  width: 100%;
}

</style>
